import full_screen from '../assets/video/full_screen.mp4';
import army1 from '../assets/images/army1.jpg';
import army2 from '../assets/images/army2.jpg';
// import army3 from '../assets/images/army3.jpg';
// import army4 from '../assets/images/army4.jpg';
import armyVideo1 from '../assets/video/army1.mp4';
import armyVideo2 from '../assets/video/army2.mp4';
import {FiPhoneCall} from "react-icons/fi";
import {BsFacebook} from "react-icons/bs";
import gallery1 from '../assets/images/gallery1.png';
import Slider from "react-slick";
import video1 from '../assets/video/video1.mp4';
import video2 from '../assets/video/video2.mp4';
import video3 from '../assets/video/video3.mp4';
import video4 from '../assets/video/video4.mp4';
import video5 from '../assets/video/video5.mp4';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/css/home/home.css';

export const Home = () => {
    const videos = [video1, video2, video3, video4, video5];

    function SampleArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "black", paddingTop: "1.5px" }}
            onClick={onClick}
          />
        );
      }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: <SampleArrow />,
        nextArrow: <SampleArrow />,
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ]
    };

 return (
     <>
         <video src={full_screen} preload='auto' muted autoPlay loop className='full_video' />

         <div className="container-fluid" id='about_us'>
            <div className="back_image">
                {/* <img src={army1} alt="photo" />
                <img src={army2} alt="photo" />
                <img src={army3} alt="photo" />
                <img src={army4} alt="photo" /> */}
                <video src={armyVideo1} muted autoPlay loop></video>
                <video src={armyVideo2} muted autoPlay loop></video>
                <img src={army1} alt="photo army1" />
                <img src={army2} alt="photo army2" />
            </div>
                <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title">Մեր մասին</div>
                        <div className="text mt-3">Մենք Վահան (Ռ.Հ.Կ) -ն ենք, մեզ մոտ անվճար ուսուցանվում է հատուկ նշանակության ստորաբաժանման մարտավարություն
                        <hr/> Հրավիրում ենք մարզումների՝ <br/>
                            Տղաների և աղջիկների <br/>
                            1-խումբ 15-18 տարեկան պատանիներ <br/>
                            2-խումբ 19-ից բարձր մեծահասակներ <br/>
                            . Ուսուցանվում է՝ <br/>
                            -Մարտավարություն <br/>
                            -Կրակային պատրաստականություն <br/>
                            -Տեղագրություն <br/>
                            -Առաջին օգնություն <br/>
                            Ուսուցումը անցկացնում են պրոֆեսիոնալ հրահանգիչները <br/>
                            Մարզումները կազմակերպվում է ոչ աշխատանքային ժամերին <br/>
                            Անվճար
                        </div>
                    </div>
                </div>
                </div>
         </div>

         <div className="container py-5" id='contact_us'>
             <div className="row">
                 <div className="col-12">
                     <div className="title">Կապ մեզ հետ</div>
                 </div>
                 <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-5 text-center">
                     <div className="icon"><FiPhoneCall /></div>
                     {/* <a href="tel:+37443240722" className="info">043-24-07-22</a> */}
                     <a href="tel:+37494540423" className="info">094-54-04-23</a>
                 </div>
                 <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-5 text-center">
                     <div className="icon"><BsFacebook /></div>
                     <a href="https://www.facebook.com/profile.php?id=100093632753623" target='_blank' className="info">Ֆեյսբուքյան էջ</a>
                 </div>
             </div>
         </div>

         <div className="container my-5">
             <div className="row">
                 <div className="col-12">
                     <div className="gallery">
                         <img src={gallery1} className='img-fluid' alt="error image"/>
                     </div>
                 </div>
                 <div className="slider-container mt-5 mb-2">
                    <Slider {...settings}>
                    {
                     videos.map((video, index)=>{
                         return (
                             <div key={index}>
                                 <video src={video} preload='metadata' controls className='w-100 h-100'></video>
                             </div>
                         )
                     })
                 }
                    </Slider>
                </div>
             </div>
             <div className="row">
                <div className='col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 mx-auto my-5 public_video'>
                        <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100093632753623%2Fvideos%2F259385867203552%2F&show_text=false&width=560&t=0" loading="lazy" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                </div>

                <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto public_photo'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02TXmDRDAtSDj5xJaWt1QCCjuVyW8cf46kPosYeed3h12UtWQgeFtinii9iR9x9y4kl%26id%3D100093632753623&show_text=true&width=500" loading="lazy" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto public_photo'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0rD6EpbjkzYU4ZiBGkQfGLEd4Yuk2P18AJvKVsZUVmYgX9RvQE1NSs1rCAioPZAjpl%26id%3D100093632753623&show_text=true&width=500" loading="lazy" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>

                {/* <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mx-auto mt-5 public_help' id='help_us'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0xDoGgUy3S9vR8shaCKWEtknqcgTSaRQdgwCdGV358WRHsY558SJK8WgLfmNXifKyl%26id%3D100093632753623&show_text=true&width=500" loading="lazy" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div> */}
             </div>
         </div>
         <div id="footer">
            <div>
                Վահան © {new Date().getFullYear()} Բոլոր իրավունքները պաշտպանված են | by <a href='https://starcodeam.github.io/' target='_blank'>Star Code</a>
            </div>
         </div>
     </>
 );
}