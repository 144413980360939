

export const FirstItems = () => {
    if(!localStorage.getItem('lang')){
        localStorage.setItem('lang', 'am');
    };

    if(window.location.href.includes('http://') && !window.location.href.includes('localhost:300')){
        window.location.href = 'https://vahan-rhk.am'+window.location.pathname;
    };
};