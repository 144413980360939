import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/images/logo.jpg';
import vahan from '../assets/images/vahan.jpg';
import '../assets/css/header/header.css';

export const Header = () => {
  const [photo, setPhoto] = useState(true);

    window.onscroll = () =>{
        const header = document.querySelector('.nav_header');
        if(window.scrollY > 0){
            header.style.top = '0';
            setPhoto(false);
        }else{
            header.style.top = '9.1vw';
            setPhoto(true);
        }
    };
  return (
      <>
        {
          photo ? <img src={vahan} id='header_first' alt="Նկարի խնդիր" /> : null
        }

          <Navbar expand="lg" className="nav_header">
      <div className='container-fluid'>
        <Navbar.Brand onClick={()=>document.getElementById('video_first').scrollIntoView({behavior: 'smooth'})}>Վահան</Navbar.Brand>
        <img src={logo} onClick={()=>document.getElementById('video_first').scrollIntoView({behavior: 'smooth'})} id='logo' alt="Նկարի խնդիր" />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto mb-2 mb-lg-0 mx-auto">
            <Nav.Link className='text-dark' onClick={()=>document.getElementById('about_us').scrollIntoView({behavior: 'smooth'})}>Մեր մասին</Nav.Link>
            <Nav.Link className='text-dark' onClick={()=>document.getElementById('contact_us').scrollIntoView({behavior: 'smooth'})}>Կապ մեզ հետ</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
      </>
  )
}